<script setup lang="ts">
   import { ChevronDownIcon, GlobeAltIcon } from '@heroicons/vue/24/outline';
   import { changeLocale } from '@formkit/vue';
   const { t } = useI18n();

   const { locale, setLocale } = useI18n();
   const setLanguage = (lang: string) => {
      const locale = useCookie('i18n_redirected');
      locale.value = lang;
      setLocale(lang);
      changeLocale(lang);
   };
   defineProps({
      location: {
         type: String,
         required: false,
      },
   });
</script>
<template>
   <BaseMenuList
      width="w-32"
      :options="[
         { name: t('shared.english'), fn: () => setLanguage('en') },
         { name: t('shared.arabic'), fn: () => setLanguage('ar') },
      ]"
      :location="location"
   >
      <BaseButton
         :prepend-icon="GlobeAltIcon"
         :append-icon="ChevronDownIcon"
         variant="plain"
         size="sm"
         color="gray-80"
      >
         {{ t(`shared.${locale}`) }}
      </BaseButton>
   </BaseMenuList>
</template>
